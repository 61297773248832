export function scrollTo(e) {
  if (!e.target) {
    return
  }

  document.getElementById("offers").scrollIntoView({
    block: "center",
    behavior: "smooth",
  });
}

export function isUK() {
  return window.location.host === 'bigbetdeal.co.uk'
}

export function addParams(link) {
  let params = window.location.search
  return params ? link + '&' + params.slice(1) : link
}
