<template>
  <footer class="Wrapper Wrapper--max MainFooter">
    <div class="MainFooter-Copyright">
      Copyright © 2021.
      <router-link to="/privacy-policy" class="MainFooter-Link">Privacy Policy</router-link>
    </div>
    <div class="MainFooter-Logos">
      <img :src="require(`@/assets/img/${logoFun}`)" alt="" class="MainFooter-Logo">
      <img :src="require(`@/assets/img/${logo18}`)" alt="" class="MainFooter-Logo Logo-18">
      <img :src="require(`@/assets/img/${logoGamble}`)" alt="" class="MainFooter-Logo">
    </div>
    <div class="MainFooter-Links">
      <router-link v-if="isUK" to="/" class="MainFooter-Link">BigBetDeal.co.uk</router-link>
      <router-link v-else to="/" class="MainFooter-Link">BigBetDeal.com</router-link>
    </div>
  </footer>
</template>

<script>
import {isUK} from "../utils";

export default {
  name: 'MainFooter',
  data() {
    return {
      logoFun: "when.png",
      logo18: "18+.png",
      logoGamble: "whenbegambleawareorg.png",

    }
  },
  computed: {
    isUK
  },
}
</script>

<style lang="scss">
.MainFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
  text-align: center;

  @media (min-width: $screen-m) {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  &-Copyright {
    margin-bottom: 15px;

    @media (min-width: $screen-m) {
      margin-bottom: 0;
    }
  }

  &-Link {
    color: var(--color-text);
  }

  &-Logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    @media (min-width: $screen-xs) {
      flex-direction: row;
    }

    @media (min-width: $screen-m) {
      margin-bottom: 0;
    }
  }

  &-Logo {
    width: 150px;

    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  .Logo-18 {
    width: 40px;
  }
}
</style>
