import '@/assets/styles/main.scss';
import VModal from 'vue-js-modal';
import Meta from 'vue-meta';
import Vue from 'vue';
import router from './router';
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(VModal);
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
