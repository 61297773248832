<template>
  <nav class="MainNav">
    <router-link to="/">
      <img src="@/assets/img/logo.svg" alt="">
    </router-link>
<!--    <div class="MainNav-Links">-->
<!--      <a href="" class="MainNav-Link MainNav-Link&#45;&#45;active">Home</a>-->
<!--      <a href="" class="MainNav-Link">Contacts</a>-->
<!--    </div>-->
  </nav>
</template>

<script>
export default {
  name: 'MainNav'
}
</script>

<style lang="scss">
.MainNav {
  position: absolute;
  top: 0;
  left: 16px;
  width: 100%;
  max-width: 1248px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 0;
  z-index: 100;

  @media (min-width: $screen-xl) {
    left: calc(50% - 625px);
  }

  &-Link {
    margin-right: 37px;
    color: var(--color-title);

    &:last-child {
      margin-right: 0;
    }

    &--active {
      color: var(--color-main1);
    }
  }
}
</style>
