import Vue from 'vue'
import Router from 'vue-router'

const HomePage = () => import(/* webpackChunkName: "homePage" */ '@/pages/HomePage.vue');
const PrivacyPolicy = () => import(/* webpackChunkName: "UA" */ '@/pages/PrivacyPolicy.vue');

Vue.use(Router);

const routes = [
  {
    path: '/',
    component: {
      template: '<router-view></router-view>',
    },

    children: [
      {
        path: '',
        name: 'HomePage',
        component: HomePage,
      },
      {
        path: 'privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {selector: to.hash};
    }
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    };
  },
});

export default router;

