<template>
  <div id="app">
    <MainNav />
    <router-view/>
    <MainFooter />
  </div>
</template>

<script>

import {isUK} from "./utils";
import MainNav from "./components/MainNav";
import MainFooter from "./components/MainFooter";

export default {
  name: 'App',
  components: {
    MainNav,
    MainFooter
  },
  metaInfo() {
    if (isUK()) {
      return {
        title: "🏆 Online Sport Betting in UK - Bet on Sports Online with Bigbetdeal.co.uk",
        meta: [
          {
            name: "description",
            content: "⚽ Bet Online on Sports in UK ☝️ Register Now and Receive Exclusive Cashback Up To 25% of Your Losses Back in All Leading Casino Brands",
          },
          {
            property: 'og:title',
            content: '🏆 Online Sport Betting in UK - Bet on Sports Online with Bigbetdeal.co.uk',
          },
          {
            property: 'og:url',
            content: 'https://bigbetdeal.co.uk',
          },
          {
            property: 'og:description',
            content: "⚽ Bet Online on Sports in UK ☝️ Register Now and Receive Exclusive Cashback Up To 25% of Your Losses Back in All Leading Casino Brands",
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:img',
            content: 'https://bigbetdeal.co.uk/hero.png',
          },
          {
            property: 'og:site_name',
            content: 'BigBetDeal',
          },
          {
            property: 'og:locale',
            content: 'en_EN',
          }
        ],
      };
    }
    return {
      title: "🏆 Online Sport Betting - Bet on Sports Online with Bigbetdeal.com",
      meta: [
        {
          name: "description",
          content: "⚽ Bet Online on Sports ☝️ Register Now and Receive Exclusive Cashback Up To 25% of Your Losses Back in All Leading Casino Brands",
        },
        {
          property: 'og:title',
          content: '🏆 Online Sport Betting - Bet on Sports Online with Bigbetdeal.com',
        },
        {
          property: 'og:url',
          content: 'https://bigbetdeal.com/',
        },
        {
          property: 'og:description',
          content: "⚽ Bet Online on Sports ☝️ Register Now and Receive Exclusive Cashback Up To 25% of Your Losses Back in All Leading Casino Brands",
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:img',
          content: 'https://bigbetdeal.com/hero.png',
        },
        {
          property: 'og:site_name',
          content: 'BigBetDeal',
        },
        {
          property: 'og:locale',
          content: 'en_EN',
        }
      ],
    };
  },
}
</script>

